.nav{
    position: relative;
    height: 50px;
    box-shadow: 1px 1px 10px -5px black;
    display: flex;
    align-items: center;
    padding-left: 100px;
    margin-bottom: 30px;
}

.nav img{
    position: relative;
    width: 45px;
    height: auto;
}

@media (max-width:550px) {
    .nav{
        padding-left: 0px;
    }
    .nav a{
        margin: 0 auto;
    }
}