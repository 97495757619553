.main-container{
    position: relative;
    margin: 0 auto;
    width: 95%;
    max-width: 1600px;
}

.main-container .img-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
}

.main-container .img-container img{
    position: relative;
    width: 100%;
    max-width: 800px;
}

.main-container{
    position:relative;
    width:80%;
    margin:0 auto;
}

.main-container h1{
    font-weight:bold;
    margin-bottom:30px;
    border-bottom: 1px solid rgb(194, 194, 194);
}


.main-container h2{
    font-weight:bold;
    margin-bottom:20px;
}

.main-container p{
    font-size:20px;
    word-wrap: break-word;
}

.main-container ul li{
    list-style-type: disc;
    font-size:18px;
    word-wrap: break-word;
}


@media (max-width:550px) {
    .main-container h1{
       font-size: 25px;
    }
}